<template>
    <div>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateSeven"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateSeven"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterSeven"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row>

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect4"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect4"
                                v-model="sortBy4"
                                :options="sortOptions4"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc4"
                                size="sm"
                                :disabled="!sortBy4"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput4"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput4"
                                v-model="filter4"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter4"
                                    @click="filter4 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <json-excel :data="seventTableExport()" name="certificados_emitidos_formularios_de_revisiones_laborales.xls"></json-excel>
                    <b-table
                        ref="table4"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage4"
                        :current-page="currentPage4"
                        :items="items4"
                        :fields="fields4"
                        :sort-by.sync="sortBy4"
                        :sort-desc.sync="sortDesc4"
                        :sort-direction="sortDirection4"
                        :filter="filter4"
                        :filter-included-fields="filterOn4"
                        @filtered="onFiltered4"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >

                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap">
                                {{ data.index+1 }}
                            </span>
                        </template>

                        <template #cell(proveedor)="data">
                            {{ data.item.proveedor.toUpperCase() }}                   
                        </template>

                        <template #cell(fecha_emision)="data">
                            <span v-if="data.item.fecha_emision">{{ data.item.fecha_emision }}</span>
                            <span v-else>---</span>                   
                        </template>
    
                    </b-table>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from '@axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {
                perPage4: 50000,
                pageOptions4: [10, 15, 20, 25, 30],
                totalRows4: 1,
                currentPage4: 1,

                sortBy4: '',
                sortDesc4: false,
                sortDirection4: 'asc',
                filter4: null,   
                filterOn4: [],
                fields4: [{
                    key: 'id',
                    label: 'ITEM',
                    sortable: true
                },
                {
                    key: 'proveedor',
                    label: 'PROVEEDOR',
                    sortable: true
                },
                {
                    key: 'rubro',
                    label: 'RUBRO',
                    sortable: true
                },
                {
                    key: 'mes_anno',
                    label: 'MES/AÑO DE ASIGNACIÓN',
                    sortable: true
                },
                {
                    key: 'fecha_inicio',
                    label: 'FECHA DE INICIO',
                    sortable: true
                },
                {
                    key: 'fecha_emision',
                    label: 'FECHA DE EMISIÓN',
                    sortable: true
                },
                {
                    key: 'puntaje',
                    label: 'PUNTAJE OBTENIDO',
                    sortable: true
                },
                {
                    key: 'nivel',
                    label: 'NIVEL OBTENIDO',
                    sortable: true
                }],
                items4: [],

                // ======================
               
                starDateSeven: "",
                endDateSeven: "",
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },                
            }
        },
        computed: {
            sortOptions4() {
                // Create an options list from our fields
                return this.fields4
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            }
        },
        beforeMount() {
            this.getDataTableSeptimo();
        },
        methods: {           
            async getDataTableSeptimo (){

                let response = await axios.get(`/reporteria/table-4?filterStartDate=${this.starDateSeven}&filterEndDate=${this.endDateSeven}`);
            
                this.items4 = response.data;
                this.perPage4 = this.items4.length;

                this.totalRows4 = response.data.length;

                this.currentPage4 = 1;
                this.sortBy4 = '';
                this.sortDesc4 = false;
                this.sortDirection4 = 'asc';
                this.filter4 = null;
            },
            async filterSeven (){
                await this.getDataTableSeptimo();
                this.$refs['table4'].refresh();
            },           
            onFiltered4(filteredItems) {
                this.totalRows4 = filteredItems.length;
                this.currentPage4 = 1;
            },           
            seventTableExport (){

                let array = [];

                for (let a = 0; a < this.items4.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'PROVEEDOR': this.items4[a]["proveedor"].toUpperCase(),
                        'RUBRO': this.items4[a]["rubro"],
                        'MES/ANNO DE ASIGNACION': this.items4[a]["mes_anno"],
                        'FECHA DE INICIO': this.items4[a]["fecha_inicio"],
                        'FECHA DE EMISION': this.items4[a]["fecha_emision"] ? this.items4[a]["fecha_emision"] : '---',
                        'PUNTAJE OBTENIDO': this.items4[a]["puntaje"],
                        'NIVEL OBTENIDO': this.items4[a]["nivel"]
                    };

                    array.push(objBody);
                }

                return array;
            }
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
